export const locationData = {
    "locations": [
        {
            "value": 4,
            "slug_en": "ajman",
            "slug_ar": "عجمان",
            "location": "Ajman",
            "type": 1
        },
        {
            "value": 3,
            "slug_en": "sharjah",
            "slug_ar": "الشارقة",
            "location": "Sharjah",
            "type": 1
        },
        {
            "value": 2,
            "slug_en": "abu-dhabi",
            "slug_ar": "أبوظبي",
            "location": "Abu Dhabi",
            "type": 1
        },
        {
            "value": 1,
            "slug_en": "dubai",
            "slug_ar": "دبي",
            "location": "Dubai",
            "type": 1
        },
        {
            "value": 7,
            "slug_en": "umm-al-quwain",
            "slug_ar": "أم-القوين",
            "location": "Umm Al Quwain",
            "type": 1
        },
        {
            "value": 6,
            "slug_en": "ras-al-khaimah",
            "slug_ar": "رأس-الخيمة",
            "location": "Ras Al Khaimah\n",
            "type": 1
        },
        {
            "value": 1,
            "slug_en": "dubai/jumeirah-village-circle-jvc",
            "slug_ar": "دبي/قرية-جميرا-الدائرية",
            "location": "Jumeirah Village Circle (JVC) (Dubai)",
            "type": 2
        },
        {
            "value": 2,
            "slug_en": "dubai/dubai-marina",
            "slug_ar": "دبي/دبي-مارينا",
            "location": "Dubai Marina (Dubai)",
            "type": 2
        },
        {
            "value": 3,
            "slug_en": "dubai/business-bay",
            "slug_ar": "دبي/الخليج-التجاري",
            "location": "Business Bay (Dubai)",
            "type": 2
        },
        {
            "value": 5,
            "slug_en": "dubai/al-nahda",
            "slug_ar": "دبي/النهدة",
            "location": "Al Nahda  (Dubai)",
            "type": 2
        },
        {
            "value": 6,
            "slug_en": "dubai/al-barsha",
            "slug_ar": "دبي/البرشاء",
            "location": "Al Barsha (Dubai)",
            "type": 2
        },
        {
            "value": 7,
            "slug_en": "dubai/bur-dubai",
            "slug_ar": "دبي/بر-دبي",
            "location": "Bur Dubai (Dubai)",
            "type": 2
        },
        {
            "value": 8,
            "slug_en": "dubai/deira",
            "slug_ar": "دبي/ديرة",
            "location": "Deira (Dubai)",
            "type": 2
        },
        {
            "value": 17,
            "slug_en": "dubai/jumeirah-lake-towers-jlt",
            "slug_ar": "دبي/أبراج-بحيرات-الجميرا",
            "location": "Jumeirah Lake Towers (JLT) (Dubai)",
            "type": 2
        },
        {
            "value": 19,
            "slug_en": "dubai/al-warqaa",
            "slug_ar": "دبي/الورقاء",
            "location": "Al Warqaa (Dubai)",
            "type": 2
        },
        {
            "value": 20,
            "slug_en": "dubai/dubai-hills-estate",
            "slug_ar": "دبي/دبي-هيلز-استيت",
            "location": "Dubai Hills Estate (Dubai)",
            "type": 2
        },
        {
            "value": 22,
            "slug_en": "dubai/international-city",
            "slug_ar": "دبي/المدينة-العالمية",
            "location": "International City (Dubai)",
            "type": 2
        },
        {
            "value": 24,
            "slug_en": "dubai/dubailand",
            "slug_ar": "دبي/دبي-لاند",
            "location": "Dubailand (Dubai)",
            "type": 2
        },
        {
            "value": 25,
            "slug_en": "dubai/dubai-sports-city",
            "slug_ar": "دبي/الجداف",
            "location": "Dubai Sports City (Dubai)",
            "type": 2
        },
        {
            "value": 26,
            "slug_en": "dubai/al-jaddaf",
            "slug_ar": null,
            "location": "Al Jaddaf (Dubai)",
            "type": 2
        },
        {
            "value": 27,
            "slug_en": "dubai/al-satwa",
            "slug_ar": "دبي/السطوة",
            "location": "Al Satwa (Dubai)",
            "type": 2
        },
        {
            "value": 31,
            "slug_en": "dubai/al-qusais",
            "slug_ar": "دبي/القصيص",
            "location": "Al Qusais (Dubai)",
            "type": 2
        },
        {
            "value": 32,
            "slug_en": "dubai/dubai-residence-complex",
            "slug_ar": "دبي/مجمع-دبي-ريزيدنس",
            "location": "Dubai Residence Complex (Dubai)",
            "type": 2
        },
        {
            "value": 33,
            "slug_en": "dubai/al-karama",
            "slug_ar": "دبي/الكرامة",
            "location": "Al Karama (Dubai)",
            "type": 2
        },
        {
            "value": 34,
            "slug_en": "dubai/umm-suqeim",
            "slug_ar": "دبي/أم-سقیم",
            "location": "Umm Suqeim (Dubai)",
            "type": 2
        },
        {
            "value": 38,
            "slug_en": "dubai/muhaisnah",
            "slug_ar": "دبي/محيصنة",
            "location": "Muhaisnah (Dubai)",
            "type": 2
        },
        {
            "value": 49,
            "slug_en": "dubai/mohammed-bin-rashid-city",
            "slug_ar": "دبي/مدينة-محمد-بن-راشد",
            "location": "Mohammed Bin Rashid City (Dubai)",
            "type": 2
        },
        {
            "value": 52,
            "slug_en": "dubai/jumeirah-village-triangle-jvt",
            "slug_ar": "دبي/مثلث-قرية-الجميرا-jvt",
            "location": "Jumeirah Village Triangle (JVT) (Dubai)",
            "type": 2
        },
        {
            "value": 54,
            "slug_en": "dubai/dubai-investment-park-dip",
            "slug_ar": "دبي/جميرا-بارك",
            "location": "Dubai Investment Park (DIP) (Dubai)",
            "type": 2
        },
        {
            "value": 56,
            "slug_en": "dubai/al-mamzar",
            "slug_ar": "دبي/الممزر",
            "location": "Al Mamzar (Dubai)",
            "type": 2
        },
        {
            "value": 59,
            "slug_en": "dubai/arabian-ranches",
            "slug_ar": "دبي/ريم",
            "location": "Arabian Ranches (Dubai)",
            "type": 2
        },
        {
            "value": 66,
            "slug_en": "dubai/meydan-city",
            "slug_ar": "دبي/مدينة-ميدان",
            "location": "Meydan City (Dubai)",
            "type": 2
        },
        {
            "value": 85,
            "slug_en": "dubai/green-community",
            "slug_ar": "دبي/جرين-كوميونيتي",
            "location": "Green Community (Dubai)",
            "type": 2
        },
        {
            "value": 105,
            "slug_en": "dubai/emirates-hills",
            "slug_ar": "دبي/تلال-الإمارات‬",
            "location": "Emirates Hills (Dubai)",
            "type": 2
        },
        {
            "value": 107,
            "slug_en": "abu-dhabi/al-reem-island",
            "slug_ar": "أبوظبي/جزيرة-الريم",
            "location": "Al Reem Island (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 108,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد",
            "location": "Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 109,
            "slug_en": "abu-dhabi/al-raha-beach",
            "slug_ar": "أبوظبي/شاطئ-الراحة",
            "location": "Al Raha Beach (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 110,
            "slug_en": "abu-dhabi/khalifa-city-",
            "slug_ar": "أبوظبي/مدينة-خليفة-أ",
            "location": "Khalifa City  (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 111,
            "slug_en": "abu-dhabi/yas-island",
            "slug_ar": "أبوظبي/جزيرة-ياس",
            "location": "Yas Island (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 112,
            "slug_en": "abu-dhabi/al-khalidiyah",
            "slug_ar": "أبوظبي/الخالدية",
            "location": "Al Khalidiyah (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 113,
            "slug_en": "abu-dhabi/al-reef",
            "slug_ar": "أبوظبي/جزيرة-السعديات",
            "location": "Al Reef (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 114,
            "slug_en": "abu-dhabi/saadiyat-island",
            "slug_ar": "أبوظبي/الريف",
            "location": "Saadiyat Island (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 115,
            "slug_en": "abu-dhabi/corniche-area",
            "slug_ar": "أبوظبي/منطقة-الكورنيش",
            "location": "Corniche Area (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 116,
            "slug_en": "abu-dhabi/corniche-road",
            "slug_ar": "أبوظبي/شارع-الكورنيش",
            "location": "Corniche Road (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 117,
            "slug_en": "abu-dhabi/al-muroor",
            "slug_ar": "أبوظبي/المرور",
            "location": "Al Muroor (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 118,
            "slug_en": "abu-dhabi/hamdan-street",
            "slug_ar": "أبوظبي/شارع-حمدان",
            "location": "Hamdan Street (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 119,
            "slug_en": "abu-dhabi/tourist-club-area-tca",
            "slug_ar": "أبوظبي/منطقة-النادي-السياحي",
            "location": "Tourist Club Area (TCA) (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 120,
            "slug_en": "abu-dhabi/al-nahyan",
            "slug_ar": "أبوظبي/آل-نهيان",
            "location": "Al Nahyan (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 121,
            "slug_en": "abu-dhabi/shakhbout-city-khalifa-city-b",
            "slug_ar": "أبوظبي/مدينة-شخبوط-مدينة-خليفة-ب",
            "location": "Shakhbout City (Khalifa City B) (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 122,
            "slug_en": "abu-dhabi/al-shamkha",
            "slug_ar": "أبوظبي/الشامخة",
            "location": "Al Shamkha (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 123,
            "slug_en": "abu-dhabi/mussafah",
            "slug_ar": "أبوظبي/مصفح",
            "location": "Mussafah (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 126,
            "slug_en": "abu-dhabi/electra-street",
            "slug_ar": "أبوظبي/شارع-إلكترا‬",
            "location": "Electra Street (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 128,
            "slug_en": "abu-dhabi/al-mushrif",
            "slug_ar": "أبوظبي/المشرف",
            "location": "Al Mushrif (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 129,
            "slug_en": "abu-dhabi/al-bateen",
            "slug_ar": "أبوظبي/البطين",
            "location": "Al Bateen (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 130,
            "slug_en": "abu-dhabi/danet-abu-dhabi",
            "slug_ar": "أبوظبي/دانة-أبوظبي",
            "location": "Danet Abu Dhabi (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 132,
            "slug_en": "abu-dhabi/rawdhat-abu-dhabi",
            "slug_ar": "أبوظبي/روضة-أبوظبي",
            "location": "Rawdhat Abu Dhabi (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 135,
            "slug_en": "abu-dhabi/between-two-bridges-bain-al-jessrain",
            "slug_ar": "أبوظبي/بين-الجسرين",
            "location": "Between Two Bridges (Bain Al Jessrain) (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 139,
            "slug_en": "abu-dhabi/al-najda-street",
            "slug_ar": "أبوظبي/شارع-النجدة",
            "location": "Al Najda Street (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 141,
            "slug_en": "abu-dhabi/al-wahdah",
            "slug_ar": "أبوظبي/الوحدة",
            "location": "Al Wahdah (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 144,
            "slug_en": "abu-dhabi/eastern-road",
            "slug_ar": "أبوظبي/الطريق-الشرقي",
            "location": "Eastern Road (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 149,
            "slug_en": "abu-dhabi/defence-street",
            "slug_ar": "أبوظبي/شارع-الدفاع",
            "location": "Defence Street (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 153,
            "slug_en": "abu-dhabi/al-manaseer",
            "slug_ar": "أبوظبي/المناصير",
            "location": "Al Manaseer (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 156,
            "slug_en": "abu-dhabi/madinat-zayed",
            "slug_ar": "أبوظبي/مدينة-زايد",
            "location": "Madinat Zayed (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 162,
            "slug_en": "abu-dhabi/masdar-city",
            "slug_ar": "أبوظبي/مدينة-مصدر",
            "location": "Masdar City (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 163,
            "slug_en": "abu-dhabi/madinat-al-riyadh",
            "slug_ar": "أبوظبي/مدينة-الرياض",
            "location": "Madinat Al Riyadh (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 167,
            "slug_en": "abu-dhabi/al-zahiyah",
            "slug_ar": "أبوظبي/الزاهية",
            "location": "Al Zahiyah (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 176,
            "slug_en": "abu-dhabi/al-nasr-street",
            "slug_ar": "أبوظبي/شارع-النصر",
            "location": "Al Nasr Street (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 182,
            "slug_en": "abu-dhabi/al-muntazah",
            "slug_ar": "أبوظبي/المنتزه",
            "location": "Al Muntazah (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 183,
            "slug_en": "sharjah/al-nahda",
            "slug_ar": "الشارقة/النهدة",
            "location": "Al Nahda (Sharjah)",
            "type": 2
        },
        {
            "value": 184,
            "slug_en": "sharjah/muwaileh",
            "slug_ar": "الشارقة/مويلح",
            "location": "Muwaileh (Sharjah)",
            "type": 2
        },
        {
            "value": 185,
            "slug_en": "sharjah/muwailih-commercial",
            "slug_ar": "الشارقة/تجارية-مويلح",
            "location": "Muwailih Commercial (Sharjah)",
            "type": 2
        },
        {
            "value": 186,
            "slug_en": "sharjah/al-taawun",
            "slug_ar": "الشارقة/التعاون",
            "location": "Al Taawun (Sharjah)",
            "type": 2
        },
        {
            "value": 187,
            "slug_en": "sharjah/al-majaz",
            "slug_ar": "الشارقة/المجاز",
            "location": "Al Majaz (Sharjah)",
            "type": 2
        },
        {
            "value": 188,
            "slug_en": "sharjah/al-khan",
            "slug_ar": "الشارقة/الخان",
            "location": "Al Khan (Sharjah)",
            "type": 2
        },
        {
            "value": 189,
            "slug_en": "sharjah/al-qasimia",
            "slug_ar": "الشارقة/القاسمية",
            "location": "Al Qasimia (Sharjah)",
            "type": 2
        },
        {
            "value": 190,
            "slug_en": "sharjah/al-mamzar",
            "slug_ar": "الشارقة/الممزر",
            "location": "Al Mamzar (Sharjah)",
            "type": 2
        },
        {
            "value": 191,
            "slug_en": "sharjah/abu-shagara",
            "slug_ar": "الشارقة/أبو-شغارة",
            "location": "Abu Shagara (Sharjah)",
            "type": 2
        },
        {
            "value": 192,
            "slug_en": "sharjah/al-tai",
            "slug_ar": "الشارقة/الطي",
            "location": "Al Tai (Sharjah)",
            "type": 2
        },
        {
            "value": 193,
            "slug_en": "sharjah/barashi",
            "slug_ar": "الشارقة/براشي",
            "location": "Barashi (Sharjah)",
            "type": 2
        },
        {
            "value": 195,
            "slug_en": "sharjah/al-qulayaah",
            "slug_ar": "الشارقة/القليعة",
            "location": "Al Qulayaah (Sharjah)",
            "type": 2
        },
        {
            "value": 196,
            "slug_en": "sharjah/industrial-area",
            "slug_ar": "الشارقة/المنطقة-الصناعية",
            "location": "Industrial Area (Sharjah)",
            "type": 2
        },
        {
            "value": 197,
            "slug_en": "sharjah/bu-tina",
            "slug_ar": "الشارقة/بوطينة",
            "location": "Bu Tina (Sharjah)",
            "type": 2
        },
        {
            "value": 198,
            "slug_en": "sharjah/al-mahatah",
            "slug_ar": "الشارقة/المحطة",
            "location": "Al Mahatah (Sharjah)",
            "type": 2
        },
        {
            "value": 201,
            "slug_en": "sharjah/hoshi",
            "slug_ar": "الشارقة/حوشي",
            "location": "Hoshi (Sharjah)",
            "type": 2
        },
        {
            "value": 202,
            "slug_en": "sharjah/corniche-al-buhaira",
            "slug_ar": "الشارقة/كورنيش-البحيرة",
            "location": "Corniche Al Buhaira (Sharjah)",
            "type": 2
        },
        {
            "value": 203,
            "slug_en": "sharjah/sharqan",
            "slug_ar": "الشارقة/شرقان",
            "location": "Sharqan (Sharjah)",
            "type": 2
        },
        {
            "value": 204,
            "slug_en": "sharjah/rolla-area",
            "slug_ar": "الشارقة/منطقة-الرولة",
            "location": "Rolla Area (Sharjah)",
            "type": 2
        },
        {
            "value": 206,
            "slug_en": "sharjah/al-ghuwair",
            "slug_ar": "الشارقة/الغوير",
            "location": "Al Ghuwair (Sharjah)",
            "type": 2
        },
        {
            "value": 208,
            "slug_en": "sharjah/al-jazzat",
            "slug_ar": "الشارقة/الجزات",
            "location": "Al Jazzat (Sharjah)",
            "type": 2
        },
        {
            "value": 210,
            "slug_en": "sharjah/al-nasserya",
            "slug_ar": "الشارقة/الناصرية",
            "location": "Al Nasserya (Sharjah)",
            "type": 2
        },
        {
            "value": 211,
            "slug_en": "sharjah/al-azra",
            "slug_ar": "الشارقة/العزرة",
            "location": "Al Azra (Sharjah)",
            "type": 2
        },
        {
            "value": 212,
            "slug_en": "sharjah/al-rahmaniya",
            "slug_ar": "الشارقة/الرحمانية",
            "location": "Al Rahmaniya (Sharjah)",
            "type": 2
        },
        {
            "value": 215,
            "slug_en": "sharjah/al-yarmook",
            "slug_ar": "الشارقة/اليرموك",
            "location": "Al Yarmook (Sharjah)",
            "type": 2
        },
        {
            "value": 216,
            "slug_en": "sharjah/al-falaj",
            "slug_ar": "الشارقة/الفلج",
            "location": "Al Falaj (Sharjah)",
            "type": 2
        },
        {
            "value": 219,
            "slug_en": "sharjah/al-soor",
            "slug_ar": "الشارقة/السور",
            "location": "Al Soor (Sharjah)",
            "type": 2
        },
        {
            "value": 221,
            "slug_en": "sharjah/aljada",
            "slug_ar": "الشارقة/الجادة",
            "location": "Aljada (Sharjah)",
            "type": 2
        },
        {
            "value": 222,
            "slug_en": "sharjah/al-jubail",
            "slug_ar": "الشارقة/الجبيل",
            "location": "Al Jubail (Sharjah)",
            "type": 2
        },
        {
            "value": 224,
            "slug_en": "sharjah/al-ramtha",
            "slug_ar": "الشارقة/الرمثاء",
            "location": "Al Ramtha (Sharjah)",
            "type": 2
        },
        {
            "value": 225,
            "slug_en": "sharjah/al-mareija",
            "slug_ar": "الشارقة/المريجة",
            "location": "Al Mareija (Sharjah)",
            "type": 2
        },
        {
            "value": 226,
            "slug_en": "sharjah/al-sabkha",
            "slug_ar": "الشارقة/الصبخة",
            "location": "Al Sabkha (Sharjah)",
            "type": 2
        },
        {
            "value": 228,
            "slug_en": "sharjah/al-noaf",
            "slug_ar": "الشارقة/النوف",
            "location": "Al Noaf (Sharjah)",
            "type": 2
        },
        {
            "value": 229,
            "slug_en": "sharjah/al-ghafia",
            "slug_ar": "الشارقة/الغافیة",
            "location": "Al Ghafia (Sharjah)",
            "type": 2
        },
        {
            "value": 231,
            "slug_en": "sharjah/al-suyoh",
            "slug_ar": "الشارقة/السيوح",
            "location": "Al Suyoh (Sharjah)",
            "type": 2
        },
        {
            "value": 233,
            "slug_en": "sharjah/al-nekhailat",
            "slug_ar": "الشارقة/النخيلات",
            "location": "Al Nekhailat (Sharjah)",
            "type": 2
        },
        {
            "value": 237,
            "slug_en": "sharjah/al-saja",
            "slug_ar": "الشارقة/السجع",
            "location": "Al Saja (Sharjah)",
            "type": 2
        },
        {
            "value": 239,
            "slug_en": "sharjah/al-yash",
            "slug_ar": "الشارقة/الياش",
            "location": "Al Yash (Sharjah)",
            "type": 2
        },
        {
            "value": 240,
            "slug_en": "ajman/al-nuaimiya",
            "slug_ar": "عجمان/النعيمية",
            "location": "Al Nuaimiya (Ajman)",
            "type": 2
        },
        {
            "value": 241,
            "slug_en": "ajman/al-rashidiya",
            "slug_ar": "عجمان/الراشدية",
            "location": "Al Rashidiya (Ajman)",
            "type": 2
        },
        {
            "value": 242,
            "slug_en": "ajman/ajman-downtown",
            "slug_ar": "عجمان/عجمان-وسط-المدينة",
            "location": "Ajman Downtown (Ajman)",
            "type": 2
        },
        {
            "value": 243,
            "slug_en": "ajman/al-rawda",
            "slug_ar": "عجمان/الروضة",
            "location": "Al Rawda (Ajman)",
            "type": 2
        },
        {
            "value": 244,
            "slug_en": "ajman/al-sawan",
            "slug_ar": "عجمان/الصوان",
            "location": "Al Sawan (Ajman)",
            "type": 2
        },
        {
            "value": 245,
            "slug_en": "ajman/al-mowaihat",
            "slug_ar": "عجمان/المويهات",
            "location": "Al Mowaihat (Ajman)",
            "type": 2
        },
        {
            "value": 246,
            "slug_en": "ajman/al-jurf",
            "slug_ar": "عجمان/الجرف",
            "location": "Al Jurf (Ajman)",
            "type": 2
        },
        {
            "value": 247,
            "slug_en": "ajman/corniche-ajman",
            "slug_ar": "عجمان/كورنيش-عجمان",
            "location": "Corniche Ajman (Ajman)",
            "type": 2
        },
        {
            "value": 248,
            "slug_en": "ajman/emirates-city",
            "slug_ar": "عجمان/مدينة-الإمارات‬",
            "location": "Emirates City (Ajman)",
            "type": 2
        },
        {
            "value": 249,
            "slug_en": "ajman/garden-city",
            "slug_ar": "عجمان/جاردن-سيتي",
            "location": "Garden City (Ajman)",
            "type": 2
        },
        {
            "value": 250,
            "slug_en": "ajman/al-hamidiyah",
            "slug_ar": "عجمان/الحميدية",
            "location": "Al Hamidiyah (Ajman)",
            "type": 2
        },
        {
            "value": 251,
            "slug_en": "ajman/al-rumaila",
            "slug_ar": "عجمان/الرميلة",
            "location": "Al Rumaila (Ajman)",
            "type": 2
        },
        {
            "value": 252,
            "slug_en": "ajman/ajman-industrial",
            "slug_ar": "عجمان/عجمان-الصناعية",
            "location": "Ajman Industrial (Ajman)",
            "type": 2
        },
        {
            "value": 253,
            "slug_en": "ajman/al-bustan",
            "slug_ar": "عجمان/البستان",
            "location": "Al Bustan (Ajman)",
            "type": 2
        },
        {
            "value": 254,
            "slug_en": "ajman/musherief",
            "slug_ar": "عجمان/مشيرف",
            "location": "Musherief (Ajman)",
            "type": 2
        },
        {
            "value": 256,
            "slug_en": "ajman/sheikh-khalifa-bin-zayed-street",
            "slug_ar": "عجمان/شارع-الشيخ-خليفة-بن-زايد",
            "location": "Sheikh Khalifa Bin Zayed Street (Ajman)",
            "type": 2
        },
        {
            "value": 257,
            "slug_en": "ajman/king-faisal-street",
            "slug_ar": "عجمان/شارع-الملك-فيصل",
            "location": "King Faisal Street (Ajman)",
            "type": 2
        },
        {
            "value": 258,
            "slug_en": "ajman/al-yasmeen",
            "slug_ar": "عجمان/الياسمين",
            "location": "Al Yasmeen (Ajman)",
            "type": 2
        },
        {
            "value": 259,
            "slug_en": "ajman/al-zahya",
            "slug_ar": "عجمان/الزاهية",
            "location": "Al Zahya (Ajman)",
            "type": 2
        },
        {
            "value": 260,
            "slug_en": "ajman/al-amerah",
            "slug_ar": "عجمان/العامرة",
            "location": "Al Amerah (Ajman)",
            "type": 2
        },
        {
            "value": 262,
            "slug_en": "ajman/liwara-1",
            "slug_ar": "عجمان/ليوارة-1",
            "location": "Liwara 1 (Ajman)",
            "type": 2
        },
        {
            "value": 263,
            "slug_en": "ajman/ajman-uptown",
            "slug_ar": "عجمان/عجمان-أب-تاون",
            "location": "Ajman Uptown (Ajman)",
            "type": 2
        },
        {
            "value": 264,
            "slug_en": "ajman/al-karama-area",
            "slug_ar": "عجمان/منطقة-الكرامة",
            "location": "Al Karama Area (Ajman)",
            "type": 2
        },
        {
            "value": 265,
            "slug_en": "ajman/al-helio",
            "slug_ar": "عجمان/الحليو",
            "location": "Al Helio (Ajman)",
            "type": 2
        },
        {
            "value": 297,
            "slug_en": "ras-al-khaimah/dafan-al-nakheel",
            "slug_ar": "رأس-الخيمة/دفن-النخیل",
            "location": "Dafan Al Nakheel (Ras Al Khaimah\n)",
            "type": 2
        },
        {
            "value": 310,
            "slug_en": "umm-al-quwain/al-madar",
            "slug_ar": "أم-القيوين/المدار",
            "location": "Al Madar (Umm Al Quwain)",
            "type": 2
        },
        {
            "value": 325,
            "slug_en": "ajman/al-manama",
            "slug_ar": "عجمان/المنامة",
            "location": "Al Manama (Ajman)",
            "type": 2
        },
        {
            "value": 330,
            "slug_en": "sharjah/halwan-suburb",
            "slug_ar": "الشارقة/ضاحية-حلوان",
            "location": "Halwan Suburb (Sharjah)",
            "type": 2
        },
        {
            "value": 333,
            "slug_en": "sharjah/al-mirgab",
            "slug_ar": "الشارقة/المرقاب",
            "location": "Al Mirgab (Sharjah)",
            "type": 2
        },
        {
            "value": 336,
            "slug_en": "sharjah/al-heerah-suburb",
            "slug_ar": "الشارقة/ضاحية-الحيرة",
            "location": "Al Heerah Suburb (Sharjah)",
            "type": 2
        },
        {
            "value": 337,
            "slug_en": "sharjah/mughaidir-suburb",
            "slug_ar": "الشارقة/ضاحية-مغيدر",
            "location": "Mughaidir Suburb (Sharjah)",
            "type": 2
        },
        {
            "value": 338,
            "slug_en": "sharjah/emirates-industrial-city",
            "slug_ar": "الشارقة/مدينة-الإمارات-الصناعية",
            "location": "Emirates Industrial City (Sharjah)",
            "type": 2
        },
        {
            "value": 341,
            "slug_en": "sharjah/dasman",
            "slug_ar": "الشارقة/دسمان",
            "location": "Dasman (Sharjah)",
            "type": 2
        },
        {
            "value": 343,
            "slug_en": "sharjah/al-ghubaiba",
            "slug_ar": "الشارقة/الغبيبة",
            "location": "Al Ghubaiba (Sharjah)",
            "type": 2
        },
        {
            "value": 344,
            "slug_en": "ajman/masfout",
            "slug_ar": "عجمان/مصفوت",
            "location": "Masfout (Ajman)",
            "type": 2
        },
        {
            "value": 345,
            "slug_en": "abu-dhabi/al-marina",
            "slug_ar": "أبوظبي/المارينا",
            "location": "Al Marina (Abu Dhabi)",
            "type": 2
        },
        {
            "value": 348,
            "slug_en": "ajman/global-city",
            "slug_ar": "عجمان/المدينة-العالمية",
            "location": "Global city (Ajman)",
            "type": 2
        },
        {
            "value": 349,
            "slug_en": "ajman/al-raqaib",
            "slug_ar": "عجمان/الرقايب",
            "location": "Al Raqaib (Ajman)",
            "type": 2
        },
        {
            "value": 350,
            "slug_en": "ajman/al-alia",
            "slug_ar": "عجمان/العلياء",
            "location": "Al Alia (Ajman)",
            "type": 2
        },
        {
            "value": 14,
            "slug_en": "dubai/jumeirah-village-circle-jvc/seasons-community",
            "slug_ar": "دبي/قرية-جميرا-الدائرية/سيزونز-كوميونيتي",
            "location": "Seasons Community  Jumeirah Village Circle (JVC) (Dubai)",
            "type": 3
        },
        {
            "value": 85,
            "slug_en": "dubai/jumeirah-village-circle-jvc/masaar-residence",
            "slug_ar": "دبي/قرية-جميرا-الدائرية/مساكن-مسار",
            "location": "Masaar Residence  Jumeirah Village Circle (JVC) (Dubai)",
            "type": 3
        },
        {
            "value": 104,
            "slug_en": "dubai/dubai-marina/ocean-heights",
            "slug_ar": "دبي/دبي-مارينا/أوشن-هايتس",
            "location": "Ocean Heights  Dubai Marina (Dubai)",
            "type": 3
        },
        {
            "value": 133,
            "slug_en": "dubai/dubai-marina/23-marina",
            "slug_ar": "دبي/دبي-مارينا/مارينا-23",
            "location": "23 Marina  Dubai Marina (Dubai)",
            "type": 3
        },
        {
            "value": 168,
            "slug_en": "dubai/business-bay/sls-dubai-hotel-&-residences",
            "slug_ar": "دبي/الخليج-التجاري/فندق-إس-إل-إس-دبي",
            "location": "SLS Dubai Hotel & Residences  Business Bay (Dubai)",
            "type": 3
        },
        {
            "value": 244,
            "slug_en": "dubai/al-nahda-/al-nahda-2",
            "slug_ar": "دبي/النهدة/النهدة-2",
            "location": "Al Nahda 2  Al Nahda  (Dubai)",
            "type": 3
        },
        {
            "value": 246,
            "slug_en": "dubai/al-barsha/al-barsha-1",
            "slug_ar": "دبي/البرشاء/البرشاء-1",
            "location": "Al Barsha 1  Al Barsha (Dubai)",
            "type": 3
        },
        {
            "value": 247,
            "slug_en": "dubai/al-barsha/al-barsha-south",
            "slug_ar": "دبي/البرشاء/البرشاء-جنوب",
            "location": "Al Barsha South  Al Barsha (Dubai)",
            "type": 3
        },
        {
            "value": 251,
            "slug_en": "dubai/bur-dubai/oud-metha",
            "slug_ar": "دبي/بر-دبي/عود-ميثاء",
            "location": "Oud Metha  Bur Dubai (Dubai)",
            "type": 3
        },
        {
            "value": 276,
            "slug_en": "dubai/deira/al-wuheida",
            "slug_ar": "دبي/ديرة/الوحيدة",
            "location": "Al Wuheida  Deira (Dubai)",
            "type": 3
        },
        {
            "value": 483,
            "slug_en": "dubai/al-warqaa/al-warqaa-1",
            "slug_ar": "دبي/الورقاء/الورقاء-1",
            "location": "Al Warqaa 1  Al Warqaa (Dubai)",
            "type": 3
        },
        {
            "value": 578,
            "slug_en": "dubai/al-satwa/jumeirah-garden-city",
            "slug_ar": "دبي/السطوة/جميرا-جاردن-سيتي",
            "location": "Jumeirah Garden City  Al Satwa (Dubai)",
            "type": 3
        },
        {
            "value": 632,
            "slug_en": "dubai/al-qusais/al-qusais-industrial-area",
            "slug_ar": "دبي/القصيص/القصيص-الصناعية",
            "location": "Al Qusais Industrial Area  Al Qusais (Dubai)",
            "type": 3
        },
        {
            "value": 896,
            "slug_en": "abu-dhabi/al-reem-island/tamouh",
            "slug_ar": "أبوظبي/جزيرة-الريم/طموح",
            "location": "Tamouh  Al Reem Island (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 1201,
            "slug_en": "abu-dhabi/al-reem-island/marina-heights",
            "slug_ar": "أبوظبي/جزيرة-الريم/مارينا-هايتس",
            "location": "Marina Heights  Al Reem Island (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 1203,
            "slug_en": "abu-dhabi/al-reem-island/bay-view-tower",
            "slug_ar": "أبوظبي/جزيرة-الريم/برج-باي-فيو",
            "location": "Bay View Tower  Al Reem Island (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 1202,
            "slug_en": "abu-dhabi/al-reem-island/leaf-tower",
            "slug_ar": "أبوظبي/جزيرة-الريم/برج-ورقة",
            "location": "leaf tower  Al Reem Island (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 895,
            "slug_en": "abu-dhabi/al-reem-island/amaya-towers",
            "slug_ar": "أبوظبي/جزيرة-الريم/أبراج-أمایا",
            "location": "Amaya Towers  Al Reem Island (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 889,
            "slug_en": "abu-dhabi/al-reem-island/marina-square",
            "slug_ar": "أبوظبي/جزيرة-الريم/مارينا-سكوير",
            "location": "Marina Square  Al Reem Island (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 892,
            "slug_en": "abu-dhabi/al-reem-island/najmat-abu-dhabi",
            "slug_ar": "أبوظبي/جزيرة-الريم/نجمة-ابوظبي",
            "location": "Najmat Abu Dhabi  Al Reem Island (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 927,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-4",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-4",
            "location": "Zone 4  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 917,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-30",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-30",
            "location": "Zone 30  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 915,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-13",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-13",
            "location": "Zone 13  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 911,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-22",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-22",
            "location": "Zone 22  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 920,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-15",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-15",
            "location": "Zone 15  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 921,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-16",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-16",
            "location": "Zone 16  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 900,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-24",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-24",
            "location": "Zone 24  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 918,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-34",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-34",
            "location": "Zone 34  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 904,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-33",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-33",
            "location": "Zone 33  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 908,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-27",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-27",
            "location": "Zone 27  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 923,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-6",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-6",
            "location": "Zone 6  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 902,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-20",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-20",
            "location": "Zone 20  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 907,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-36",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-36",
            "location": "Zone 36  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 924,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-3",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-3",
            "location": "Zone 3  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 901,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-25",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-25",
            "location": "Zone 25  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 909,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-17",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-17",
            "location": "Zone 17  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 919,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-8",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-8",
            "location": "Zone 8  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 928,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/mazyad-mall",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/مزيد-مول",
            "location": "Mazyad Mall  Mohammed Bin Zayed City (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 953,
            "slug_en": "abu-dhabi/khalifa-city-/al-forsan-village",
            "slug_ar": "أبوظبي/مدينة-خليفة-أ/قرية-الفرسان‬",
            "location": "Al Forsan Village  Khalifa City  (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 957,
            "slug_en": "abu-dhabi/yas-island/water's-edge",
            "slug_ar": "أبوظبي/جزيرة-ياس/وترز-أج",
            "location": "Water's Edge  Yas Island (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 960,
            "slug_en": "abu-dhabi/yas-island/west-yas",
            "slug_ar": "أبوظبي/جزيرة-ياس/وست-ياس",
            "location": "West Yas  Yas Island (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 958,
            "slug_en": "abu-dhabi/yas-island/mayan",
            "slug_ar": "أبوظبي/جزيرة-ياس/مايان",
            "location": "Mayan  Yas Island (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 962,
            "slug_en": "abu-dhabi/al-khalidiyah/khalidiyah-street",
            "slug_ar": "أبوظبي/الخالدية/شارع-الخالدية",
            "location": "Khalidiyah Street  Al Khalidiyah (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 976,
            "slug_en": "abu-dhabi/al-reef/al-reef-downtown",
            "slug_ar": "أبوظبي/جزيرة-السعديات/الریف-داون-تاون",
            "location": "Al Reef Downtown  Al Reef (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 975,
            "slug_en": "abu-dhabi/al-reef/al-reef-villas",
            "slug_ar": "أبوظبي/جزيرة-السعديات/فلل-الريف",
            "location": "Al Reef Villas  Al Reef (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 980,
            "slug_en": "abu-dhabi/saadiyat-island/hidd-al-saadiyat",
            "slug_ar": "أبوظبي/الريف/حِد-السعديات",
            "location": "HIDD Al Saadiyat  Saadiyat Island (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 994,
            "slug_en": "abu-dhabi/al-muroor/muroor-road",
            "slug_ar": "أبوظبي/المرور/شارع-المرور",
            "location": "Muroor Road  Al Muroor (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 1001,
            "slug_en": "abu-dhabi/mussafah/shabiya",
            "slug_ar": "أبوظبي/مصفح/شعبية",
            "location": "Shabiya  Mussafah (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 1013,
            "slug_en": "abu-dhabi/al-mushrif/delma-street",
            "slug_ar": "أبوظبي/المشرف/شارع-دلما",
            "location": "Delma Street  Al Mushrif (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 1055,
            "slug_en": "abu-dhabi/eastern-road/khalifa-park",
            "slug_ar": "أبوظبي/الطريق-الشرقي/منتزه-خليفة",
            "location": "Khalifa Park  Eastern Road (Abu Dhabi)",
            "type": 3
        },
        {
            "value": 1096,
            "slug_en": "sharjah/muwaileh/al-zahia",
            "slug_ar": "الشارقة/مويلح/الزاهية",
            "location": "Al Zahia  Muwaileh (Sharjah)",
            "type": 3
        },
        {
            "value": 1105,
            "slug_en": "sharjah/al-taawun/al-taawun-street",
            "slug_ar": "الشارقة/التعاون/شارع-التعاون",
            "location": "Al Taawun Street  Al Taawun (Sharjah)",
            "type": 3
        },
        {
            "value": 1109,
            "slug_en": "sharjah/al-majaz/al-majaz-2",
            "slug_ar": "الشارقة/المجاز/المجاز-2",
            "location": "Al Majaz 2  Al Majaz (Sharjah)",
            "type": 3
        },
        {
            "value": 1108,
            "slug_en": "sharjah/al-majaz/al-majaz-3",
            "slug_ar": "الشارقة/المجاز/المجاز-3",
            "location": "Al Majaz 3  Al Majaz (Sharjah)",
            "type": 3
        },
        {
            "value": 1110,
            "slug_en": "sharjah/al-majaz/al-majaz-1",
            "slug_ar": "الشارقة/المجاز/المجاز-1",
            "location": "Al Majaz 1  Al Majaz (Sharjah)",
            "type": 3
        },
        {
            "value": 1119,
            "slug_en": "sharjah/al-qasimia/al-nud",
            "slug_ar": "الشارقة/القاسمية/الند",
            "location": "Al Nud  Al Qasimia (Sharjah)",
            "type": 3
        },
        {
            "value": 1127,
            "slug_en": "sharjah/industrial-area/industrial-area-1",
            "slug_ar": "الشارقة/المنطقة-الصناعية/المنطقة-الصناعية-1",
            "location": "Industrial Area 1  Industrial Area (Sharjah)",
            "type": 3
        },
        {
            "value": 1129,
            "slug_en": "sharjah/industrial-area/industrial-area-11",
            "slug_ar": "الشارقة/المنطقة-الصناعية/المنطقة-الصناعية-11",
            "location": "Industrial Area 11  Industrial Area (Sharjah)",
            "type": 3
        },
        {
            "value": 1128,
            "slug_en": "sharjah/industrial-area/industrial-area-4",
            "slug_ar": "الشارقة/المنطقة-الصناعية/المنطقة-الصناعية-4",
            "location": "Industrial Area 4  Industrial Area (Sharjah)",
            "type": 3
        },
        {
            "value": 1130,
            "slug_en": "sharjah/industrial-area/industrial-area-13",
            "slug_ar": "الشارقة/المنطقة-الصناعية/المنطقة-الصناعية-13",
            "location": "Industrial Area 13  Industrial Area (Sharjah)",
            "type": 3
        },
        {
            "value": 1133,
            "slug_en": "sharjah/rolla-area/rolla-square",
            "slug_ar": "الشارقة/منطقة-الرولة/ميدان-الرولة",
            "location": "Rolla Square  Rolla Area (Sharjah)",
            "type": 3
        },
        {
            "value": 1200,
            "slug_en": "ajman/al-nuaimiya/city-tower",
            "slug_ar": "عجمان/النعيمية/برج-المدينة",
            "location": "city tower  Al Nuaimiya (Ajman)",
            "type": 3
        },
        {
            "value": 1134,
            "slug_en": "ajman/al-nuaimiya/al-nuaimiya-3",
            "slug_ar": "عجمان/النعيمية/النعيمية-3",
            "location": "Al Nuaimiya 3  Al Nuaimiya (Ajman)",
            "type": 3
        },
        {
            "value": 1137,
            "slug_en": "ajman/al-nuaimiya/al-nuaimiya-1",
            "slug_ar": "عجمان/النعيمية/النعيمية-1",
            "location": "Al Nuaimiya 1  Al Nuaimiya (Ajman)",
            "type": 3
        },
        {
            "value": 1136,
            "slug_en": "ajman/al-nuaimiya/al-nuaimiya-towers",
            "slug_ar": "عجمان/النعيمية/أبراج-النعيمية",
            "location": "Al Nuaimiya Towers  Al Nuaimiya (Ajman)",
            "type": 3
        },
        {
            "value": 1135,
            "slug_en": "ajman/al-nuaimiya/al-nuaimiya-2",
            "slug_ar": "عجمان/النعيمية/النعيمية-2",
            "location": "Al Nuaimiya 2  Al Nuaimiya (Ajman)",
            "type": 3
        },
        {
            "value": 1142,
            "slug_en": "ajman/al-rashidiya/falcon-towers",
            "slug_ar": "عجمان/الراشدية/فالكون-تاورز",
            "location": "Falcon Towers  Al Rashidiya (Ajman)",
            "type": 3
        },
        {
            "value": 1143,
            "slug_en": "ajman/al-rashidiya/oasis-tower",
            "slug_ar": "عجمان/الراشدية/أبراج-الواحة",
            "location": "Oasis Tower  Al Rashidiya (Ajman)",
            "type": 3
        },
        {
            "value": 1144,
            "slug_en": "ajman/al-rashidiya/zara-residence",
            "slug_ar": "عجمان/الراشدية/زارا-ريزيدنس",
            "location": "Zara Residence  Al Rashidiya (Ajman)",
            "type": 3
        },
        {
            "value": 1140,
            "slug_en": "ajman/al-rashidiya/al-rashidiya-3",
            "slug_ar": "عجمان/الراشدية/الراشدية-3",
            "location": "Al Rashidiya 3  Al Rashidiya (Ajman)",
            "type": 3
        },
        {
            "value": 1138,
            "slug_en": "ajman/al-rashidiya/al-rashidiya-2",
            "slug_ar": "عجمان/الراشدية/الراشدية-2",
            "location": "Al Rashidiya 2  Al Rashidiya (Ajman)",
            "type": 3
        },
        {
            "value": 1141,
            "slug_en": "ajman/al-rashidiya/al-rashidiya-1",
            "slug_ar": "عجمان/الراشدية/الراشدية-1",
            "location": "Al Rashidiya 1  Al Rashidiya (Ajman)",
            "type": 3
        },
        {
            "value": 1147,
            "slug_en": "ajman/ajman-downtown/ajman-pearl-towers",
            "slug_ar": "عجمان/عجمان-وسط-المدينة/أبراج-لؤلؤة-عجمان",
            "location": "Ajman Pearl Towers  Ajman Downtown (Ajman)",
            "type": 3
        },
        {
            "value": 1145,
            "slug_en": "ajman/ajman-downtown/horizon-tower",
            "slug_ar": "عجمان/عجمان-وسط-المدينة/أبراج-الهورايزون",
            "location": "Horizon Tower  Ajman Downtown (Ajman)",
            "type": 3
        },
        {
            "value": 1146,
            "slug_en": "ajman/ajman-downtown/al-khor-towers",
            "slug_ar": "عجمان/عجمان-وسط-المدينة/أبراج-الخور",
            "location": "Al Khor Towers  Ajman Downtown (Ajman)",
            "type": 3
        },
        {
            "value": 1149,
            "slug_en": "ajman/al-rawda/al-rawda-2",
            "slug_ar": "عجمان/الروضة/الروضة-2",
            "location": "Al Rawda 2  Al Rawda (Ajman)",
            "type": 3
        },
        {
            "value": 1148,
            "slug_en": "ajman/al-rawda/al-rawda-3",
            "slug_ar": "عجمان/الروضة/الروضة-3",
            "location": "Al Rawda 3  Al Rawda (Ajman)",
            "type": 3
        },
        {
            "value": 1150,
            "slug_en": "ajman/al-rawda/al-rawda-1",
            "slug_ar": "عجمان/الروضة/الروضة-1",
            "location": "Al Rawda 1  Al Rawda (Ajman)",
            "type": 3
        },
        {
            "value": 1151,
            "slug_en": "ajman/al-sawan/ajman-one-tower",
            "slug_ar": "عجمان/الصوان/أبراج-عجمان-ون",
            "location": "Ajman One Tower  Al Sawan (Ajman)",
            "type": 3
        },
        {
            "value": 1154,
            "slug_en": "ajman/al-mowaihat/al-mowaihat-1",
            "slug_ar": "عجمان/المويهات/المويهات-1",
            "location": "Al Mowaihat 1  Al Mowaihat (Ajman)",
            "type": 3
        },
        {
            "value": 1152,
            "slug_en": "ajman/al-mowaihat/al-mowaihat-3",
            "slug_ar": "عجمان/المويهات/المويهات-3",
            "location": "Al Mowaihat 3  Al Mowaihat (Ajman)",
            "type": 3
        },
        {
            "value": 1158,
            "slug_en": "ajman/al-jurf/al-jurf-1",
            "slug_ar": "عجمان/الجرف/الجرف-1",
            "location": "Al Jurf 1  Al Jurf (Ajman)",
            "type": 3
        },
        {
            "value": 1157,
            "slug_en": "ajman/al-jurf/al-jurf-industrial-area",
            "slug_ar": "عجمان/الجرف/المنطقة-الصناعية-الجرف",
            "location": "Al Jurf Industrial Area  Al Jurf (Ajman)",
            "type": 3
        },
        {
            "value": 1155,
            "slug_en": "ajman/al-jurf/al-jurf-2",
            "slug_ar": "عجمان/الجرف/الجرف-2",
            "location": "Al Jurf 2  Al Jurf (Ajman)",
            "type": 3
        },
        {
            "value": 1160,
            "slug_en": "ajman/corniche-ajman/corniche-tower",
            "slug_ar": "عجمان/كورنيش-عجمان/برج-الكورنيش",
            "location": "Corniche Tower  Corniche Ajman (Ajman)",
            "type": 3
        },
        {
            "value": 1159,
            "slug_en": "ajman/corniche-ajman/ajman-corniche-residence",
            "slug_ar": "عجمان/كورنيش-عجمان/مساكن-كورنيش-عجمان",
            "location": "Ajman Corniche Residence  Corniche Ajman (Ajman)",
            "type": 3
        },
        {
            "value": 1208,
            "slug_en": "ajman/emirates-city/emirates-towers",
            "slug_ar": "عجمان/مدينة-الإمارات‬/ابراج-الامارات",
            "location": "Emirates towers  Emirates City (Ajman)",
            "type": 3
        },
        {
            "value": 1168,
            "slug_en": "ajman/al-hamidiyah/al-hamidiya-1",
            "slug_ar": "عجمان/الحميدية/الحميدية-1",
            "location": "Al Hamidiya 1  Al Hamidiyah (Ajman)",
            "type": 3
        },
        {
            "value": 1170,
            "slug_en": "ajman/al-bustan/orient-towers",
            "slug_ar": "عجمان/البستان/أبراج-أورينت",
            "location": "Orient Towers  Al Bustan (Ajman)",
            "type": 3
        },
        {
            "value": 1196,
            "slug_en": "ras-al-khaimah/dafan-al-nakheel/julphar-towers",
            "slug_ar": "رأس-الخيمة/دفن-النخیل/جلفار-تاورز",
            "location": "Julphar Towers  Dafan Al Nakheel (Ras Al Khaimah\n)",
            "type": 3
        },
        {
            "value": 1210,
            "slug_en": "ajman/al-raqaib/al-raqaib-2",
            "slug_ar": "عجمان/الرقايب/الرقايب-2",
            "location": "Al Raqaib 2  Al Raqaib (Ajman)",
            "type": 3
        }
    ],
    "locations_ar": [
        {
            "value": 4,
            "slug_en": "ajman",
            "slug_ar": "عجمان",
            "location": "عجمان",
            "type": 1
        },
        {
            "value": 3,
            "slug_en": "sharjah",
            "slug_ar": "الشارقة",
            "location": "الشارقة",
            "type": 1
        },
        {
            "value": 2,
            "slug_en": "abu-dhabi",
            "slug_ar": "أبوظبي",
            "location": "أبوظبي",
            "type": 1
        },
        {
            "value": 1,
            "slug_en": "dubai",
            "slug_ar": "دبي",
            "location": "دبي",
            "type": 1
        },
        {
            "value": 7,
            "slug_en": "umm-al-quwain",
            "slug_ar": "أم-القوين",
            "location": "أم القيوين\n",
            "type": 1
        },
        {
            "value": 6,
            "slug_en": "ras-al-khaimah",
            "slug_ar": "رأس-الخيمة",
            "location": "رأس الخيمة",
            "type": 1
        },
        {
            "value": 1,
            "slug_ar": "دبي/قرية-جميرا-الدائرية",
            "slug_en": "dubai/jumeirah-village-circle-jvc",
            "location": "قرية جميرا الدائرية (دبي)",
            "type": 2
        },
        {
            "value": 2,
            "slug_ar": "دبي/دبي-مارينا",
            "slug_en": "dubai/dubai-marina",
            "location": "دبي مارينا (دبي)",
            "type": 2
        },
        {
            "value": 3,
            "slug_ar": "دبي/الخليج-التجاري",
            "slug_en": "dubai/business-bay",
            "location": "الخليج التجاري (دبي)",
            "type": 2
        },
        {
            "value": 5,
            "slug_ar": "دبي/النهدة",
            "slug_en": "dubai/al-nahda",
            "location": "النهدة (دبي)",
            "type": 2
        },
        {
            "value": 6,
            "slug_ar": "دبي/البرشاء",
            "slug_en": "dubai/al-barsha",
            "location": "البرشاء (دبي)",
            "type": 2
        },
        {
            "value": 7,
            "slug_ar": "دبي/بر-دبي",
            "slug_en": "dubai/bur-dubai",
            "location": "بر دبي (دبي)",
            "type": 2
        },
        {
            "value": 8,
            "slug_ar": "دبي/ديرة",
            "slug_en": "dubai/deira",
            "location": "ديرة (دبي)",
            "type": 2
        },
        {
            "value": 17,
            "slug_ar": "دبي/أبراج-بحيرات-الجميرا",
            "slug_en": "dubai/jumeirah-lake-towers-jlt",
            "location": "أبراج بحيرات الجميرا (دبي)",
            "type": 2
        },
        {
            "value": 19,
            "slug_ar": "دبي/الورقاء",
            "slug_en": "dubai/al-warqaa",
            "location": "الورقاء (دبي)",
            "type": 2
        },
        {
            "value": 20,
            "slug_ar": "دبي/دبي-هيلز-استيت",
            "slug_en": "dubai/dubai-hills-estate",
            "location": "دبي هيلز استيت (دبي)",
            "type": 2
        },
        {
            "value": 22,
            "slug_ar": "دبي/المدينة-العالمية",
            "slug_en": "dubai/international-city",
            "location": "المدينة العالمية (دبي)",
            "type": 2
        },
        {
            "value": 24,
            "slug_ar": "دبي/دبي-لاند",
            "slug_en": "dubai/dubailand",
            "location": "دبي لاند (دبي)",
            "type": 2
        },
        {
            "value": 25,
            "slug_ar": "دبي/الجداف",
            "slug_en": "dubai/dubai-sports-city",
            "location": "الجداف (دبي)",
            "type": 2
        },
        {
            "value": 26,
            "slug_ar": null,
            "slug_en": "dubai/al-jaddaf",
            "location": "مدينة دبي الرياضية (دبي)",
            "type": 2
        },
        {
            "value": 27,
            "slug_ar": "دبي/السطوة",
            "slug_en": "dubai/al-satwa",
            "location": "السطوة (دبي)",
            "type": 2
        },
        {
            "value": 31,
            "slug_ar": "دبي/القصيص",
            "slug_en": "dubai/al-qusais",
            "location": "القصيص (دبي)",
            "type": 2
        },
        {
            "value": 32,
            "slug_ar": "دبي/مجمع-دبي-ريزيدنس",
            "slug_en": "dubai/dubai-residence-complex",
            "location": "مجمع دبي ريزيدنس (دبي)",
            "type": 2
        },
        {
            "value": 33,
            "slug_ar": "دبي/الكرامة",
            "slug_en": "dubai/al-karama",
            "location": "الكرامة (دبي)",
            "type": 2
        },
        {
            "value": 34,
            "slug_ar": "دبي/أم-سقیم",
            "slug_en": "dubai/umm-suqeim",
            "location": "أم سقیم (دبي)",
            "type": 2
        },
        {
            "value": 38,
            "slug_ar": "دبي/محيصنة",
            "slug_en": "dubai/muhaisnah",
            "location": "محيصنة (دبي)",
            "type": 2
        },
        {
            "value": 49,
            "slug_ar": "دبي/مدينة-محمد-بن-راشد",
            "slug_en": "dubai/mohammed-bin-rashid-city",
            "location": "مدينة محمد بن راشد (دبي)",
            "type": 2
        },
        {
            "value": 52,
            "slug_ar": "دبي/مثلث-قرية-الجميرا-jvt",
            "slug_en": "dubai/jumeirah-village-triangle-jvt",
            "location": "مثلث قرية الجميرا (JVT) (دبي)",
            "type": 2
        },
        {
            "value": 54,
            "slug_ar": "دبي/جميرا-بارك",
            "slug_en": "dubai/dubai-investment-park-dip",
            "location": "جميرا بارك (دبي)",
            "type": 2
        },
        {
            "value": 56,
            "slug_ar": "دبي/الممزر",
            "slug_en": "dubai/al-mamzar",
            "location": "الممزر (دبي)",
            "type": 2
        },
        {
            "value": 59,
            "slug_ar": "دبي/ريم",
            "slug_en": "dubai/arabian-ranches",
            "location": "ريم (دبي)",
            "type": 2
        },
        {
            "value": 66,
            "slug_ar": "دبي/مدينة-ميدان",
            "slug_en": "dubai/meydan-city",
            "location": "مدينة ميدان (دبي)",
            "type": 2
        },
        {
            "value": 85,
            "slug_ar": "دبي/جرين-كوميونيتي",
            "slug_en": "dubai/green-community",
            "location": "جرين كوميونيتي (دبي)",
            "type": 2
        },
        {
            "value": 105,
            "slug_ar": "دبي/تلال-الإمارات‬",
            "slug_en": "dubai/emirates-hills",
            "location": "تلال الإمارات‬ (دبي)",
            "type": 2
        },
        {
            "value": 107,
            "slug_ar": "أبوظبي/جزيرة-الريم",
            "slug_en": "abu-dhabi/al-reem-island",
            "location": "جزيرة الريم (أبوظبي)",
            "type": 2
        },
        {
            "value": 108,
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد",
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city",
            "location": "مدينة محمد بن زايد (أبوظبي)",
            "type": 2
        },
        {
            "value": 109,
            "slug_ar": "أبوظبي/شاطئ-الراحة",
            "slug_en": "abu-dhabi/al-raha-beach",
            "location": "شاطئ الراحة (أبوظبي)",
            "type": 2
        },
        {
            "value": 110,
            "slug_ar": "أبوظبي/مدينة-خليفة-أ",
            "slug_en": "abu-dhabi/khalifa-city-",
            "location": "مدينة خليفة أ (أبوظبي)",
            "type": 2
        },
        {
            "value": 111,
            "slug_ar": "أبوظبي/جزيرة-ياس",
            "slug_en": "abu-dhabi/yas-island",
            "location": "جزيرة ياس (أبوظبي)",
            "type": 2
        },
        {
            "value": 112,
            "slug_ar": "أبوظبي/الخالدية",
            "slug_en": "abu-dhabi/al-khalidiyah",
            "location": "الخالدية (أبوظبي)",
            "type": 2
        },
        {
            "value": 113,
            "slug_ar": "أبوظبي/جزيرة-السعديات",
            "slug_en": "abu-dhabi/al-reef",
            "location": "جزيرة السعديات (أبوظبي)",
            "type": 2
        },
        {
            "value": 114,
            "slug_ar": "أبوظبي/الريف",
            "slug_en": "abu-dhabi/saadiyat-island",
            "location": "الريف (أبوظبي)",
            "type": 2
        },
        {
            "value": 115,
            "slug_ar": "أبوظبي/منطقة-الكورنيش",
            "slug_en": "abu-dhabi/corniche-area",
            "location": "منطقة الكورنيش (أبوظبي)",
            "type": 2
        },
        {
            "value": 116,
            "slug_ar": "أبوظبي/شارع-الكورنيش",
            "slug_en": "abu-dhabi/corniche-road",
            "location": "شارع الكورنيش (أبوظبي)",
            "type": 2
        },
        {
            "value": 117,
            "slug_ar": "أبوظبي/المرور",
            "slug_en": "abu-dhabi/al-muroor",
            "location": "المرور (أبوظبي)",
            "type": 2
        },
        {
            "value": 118,
            "slug_ar": "أبوظبي/شارع-حمدان",
            "slug_en": "abu-dhabi/hamdan-street",
            "location": "شارع حمدان (أبوظبي)",
            "type": 2
        },
        {
            "value": 119,
            "slug_ar": "أبوظبي/منطقة-النادي-السياحي",
            "slug_en": "abu-dhabi/tourist-club-area-tca",
            "location": "منطقة النادي السياحي (أبوظبي)",
            "type": 2
        },
        {
            "value": 120,
            "slug_ar": "أبوظبي/آل-نهيان",
            "slug_en": "abu-dhabi/al-nahyan",
            "location": "آل نهيان (أبوظبي)",
            "type": 2
        },
        {
            "value": 121,
            "slug_ar": "أبوظبي/مدينة-شخبوط-مدينة-خليفة-ب",
            "slug_en": "abu-dhabi/shakhbout-city-khalifa-city-b",
            "location": "مدينة شخبوط (مدينة خليفة ب) (أبوظبي)",
            "type": 2
        },
        {
            "value": 122,
            "slug_ar": "أبوظبي/الشامخة",
            "slug_en": "abu-dhabi/al-shamkha",
            "location": "الشامخة (أبوظبي)",
            "type": 2
        },
        {
            "value": 123,
            "slug_ar": "أبوظبي/مصفح",
            "slug_en": "abu-dhabi/mussafah",
            "location": "مصفح (أبوظبي)",
            "type": 2
        },
        {
            "value": 126,
            "slug_ar": "أبوظبي/شارع-إلكترا‬",
            "slug_en": "abu-dhabi/electra-street",
            "location": "شارع إلكترا‬ (أبوظبي)",
            "type": 2
        },
        {
            "value": 128,
            "slug_ar": "أبوظبي/المشرف",
            "slug_en": "abu-dhabi/al-mushrif",
            "location": "المشرف (أبوظبي)",
            "type": 2
        },
        {
            "value": 129,
            "slug_ar": "أبوظبي/البطين",
            "slug_en": "abu-dhabi/al-bateen",
            "location": "البطين (أبوظبي)",
            "type": 2
        },
        {
            "value": 130,
            "slug_ar": "أبوظبي/دانة-أبوظبي",
            "slug_en": "abu-dhabi/danet-abu-dhabi",
            "location": "دانة أبوظبي (أبوظبي)",
            "type": 2
        },
        {
            "value": 132,
            "slug_ar": "أبوظبي/روضة-أبوظبي",
            "slug_en": "abu-dhabi/rawdhat-abu-dhabi",
            "location": "روضة أبوظبي (أبوظبي)",
            "type": 2
        },
        {
            "value": 135,
            "slug_ar": "أبوظبي/بين-الجسرين",
            "slug_en": "abu-dhabi/between-two-bridges-bain-al-jessrain",
            "location": "بين الجسرين (أبوظبي)",
            "type": 2
        },
        {
            "value": 139,
            "slug_ar": "أبوظبي/شارع-النجدة",
            "slug_en": "abu-dhabi/al-najda-street",
            "location": "شارع النجدة (أبوظبي)",
            "type": 2
        },
        {
            "value": 141,
            "slug_ar": "أبوظبي/الوحدة",
            "slug_en": "abu-dhabi/al-wahdah",
            "location": "الوحدة (أبوظبي)",
            "type": 2
        },
        {
            "value": 144,
            "slug_ar": "أبوظبي/الطريق-الشرقي",
            "slug_en": "abu-dhabi/eastern-road",
            "location": "الطريق الشرقي (أبوظبي)",
            "type": 2
        },
        {
            "value": 149,
            "slug_ar": "أبوظبي/شارع-الدفاع",
            "slug_en": "abu-dhabi/defence-street",
            "location": "شارع الدفاع (أبوظبي)",
            "type": 2
        },
        {
            "value": 153,
            "slug_ar": "أبوظبي/المناصير",
            "slug_en": "abu-dhabi/al-manaseer",
            "location": "المناصير (أبوظبي)",
            "type": 2
        },
        {
            "value": 156,
            "slug_ar": "أبوظبي/مدينة-زايد",
            "slug_en": "abu-dhabi/madinat-zayed",
            "location": "مدينة زايد (أبوظبي)",
            "type": 2
        },
        {
            "value": 162,
            "slug_ar": "أبوظبي/مدينة-مصدر",
            "slug_en": "abu-dhabi/masdar-city",
            "location": "مدينة مصدر (أبوظبي)",
            "type": 2
        },
        {
            "value": 163,
            "slug_ar": "أبوظبي/مدينة-الرياض",
            "slug_en": "abu-dhabi/madinat-al-riyadh",
            "location": "مدينة الرياض (أبوظبي)",
            "type": 2
        },
        {
            "value": 167,
            "slug_ar": "أبوظبي/الزاهية",
            "slug_en": "abu-dhabi/al-zahiyah",
            "location": "الزاهية (أبوظبي)",
            "type": 2
        },
        {
            "value": 176,
            "slug_ar": "أبوظبي/شارع-النصر",
            "slug_en": "abu-dhabi/al-nasr-street",
            "location": "شارع النصر (أبوظبي)",
            "type": 2
        },
        {
            "value": 182,
            "slug_ar": "أبوظبي/المنتزه",
            "slug_en": "abu-dhabi/al-muntazah",
            "location": "المنتزه (أبوظبي)",
            "type": 2
        },
        {
            "value": 183,
            "slug_ar": "الشارقة/النهدة",
            "slug_en": "sharjah/al-nahda",
            "location": "النهدة (الشارقة)",
            "type": 2
        },
        {
            "value": 184,
            "slug_ar": "الشارقة/مويلح",
            "slug_en": "sharjah/muwaileh",
            "location": "مويلح (الشارقة)",
            "type": 2
        },
        {
            "value": 185,
            "slug_ar": "الشارقة/تجارية-مويلح",
            "slug_en": "sharjah/muwailih-commercial",
            "location": "تجارية مويلح (الشارقة)",
            "type": 2
        },
        {
            "value": 186,
            "slug_ar": "الشارقة/التعاون",
            "slug_en": "sharjah/al-taawun",
            "location": "التعاون (الشارقة)",
            "type": 2
        },
        {
            "value": 187,
            "slug_ar": "الشارقة/المجاز",
            "slug_en": "sharjah/al-majaz",
            "location": "المجاز (الشارقة)",
            "type": 2
        },
        {
            "value": 188,
            "slug_ar": "الشارقة/الخان",
            "slug_en": "sharjah/al-khan",
            "location": "الخان (الشارقة)",
            "type": 2
        },
        {
            "value": 189,
            "slug_ar": "الشارقة/القاسمية",
            "slug_en": "sharjah/al-qasimia",
            "location": "القاسمية (الشارقة)",
            "type": 2
        },
        {
            "value": 190,
            "slug_ar": "الشارقة/الممزر",
            "slug_en": "sharjah/al-mamzar",
            "location": "الممزر (الشارقة)",
            "type": 2
        },
        {
            "value": 191,
            "slug_ar": "الشارقة/أبو-شغارة",
            "slug_en": "sharjah/abu-shagara",
            "location": "أبو شغارة (الشارقة)",
            "type": 2
        },
        {
            "value": 192,
            "slug_ar": "الشارقة/الطي",
            "slug_en": "sharjah/al-tai",
            "location": "الطي (الشارقة)",
            "type": 2
        },
        {
            "value": 193,
            "slug_ar": "الشارقة/براشي",
            "slug_en": "sharjah/barashi",
            "location": "براشي (الشارقة)",
            "type": 2
        },
        {
            "value": 195,
            "slug_ar": "الشارقة/القليعة",
            "slug_en": "sharjah/al-qulayaah",
            "location": "القليعة (الشارقة)",
            "type": 2
        },
        {
            "value": 196,
            "slug_ar": "الشارقة/المنطقة-الصناعية",
            "slug_en": "sharjah/industrial-area",
            "location": "المنطقة الصناعية (الشارقة)",
            "type": 2
        },
        {
            "value": 197,
            "slug_ar": "الشارقة/بوطينة",
            "slug_en": "sharjah/bu-tina",
            "location": "بوطينة (الشارقة)",
            "type": 2
        },
        {
            "value": 198,
            "slug_ar": "الشارقة/المحطة",
            "slug_en": "sharjah/al-mahatah",
            "location": "المحطة (الشارقة)",
            "type": 2
        },
        {
            "value": 201,
            "slug_ar": "الشارقة/حوشي",
            "slug_en": "sharjah/hoshi",
            "location": "حوشي (الشارقة)",
            "type": 2
        },
        {
            "value": 202,
            "slug_ar": "الشارقة/كورنيش-البحيرة",
            "slug_en": "sharjah/corniche-al-buhaira",
            "location": "كورنيش البحيرة (الشارقة)",
            "type": 2
        },
        {
            "value": 203,
            "slug_ar": "الشارقة/شرقان",
            "slug_en": "sharjah/sharqan",
            "location": "شرقان (الشارقة)",
            "type": 2
        },
        {
            "value": 204,
            "slug_ar": "الشارقة/منطقة-الرولة",
            "slug_en": "sharjah/rolla-area",
            "location": "منطقة الرولة (الشارقة)",
            "type": 2
        },
        {
            "value": 206,
            "slug_ar": "الشارقة/الغوير",
            "slug_en": "sharjah/al-ghuwair",
            "location": "الغوير (الشارقة)",
            "type": 2
        },
        {
            "value": 208,
            "slug_ar": "الشارقة/الجزات",
            "slug_en": "sharjah/al-jazzat",
            "location": "الجزات (الشارقة)",
            "type": 2
        },
        {
            "value": 210,
            "slug_ar": "الشارقة/الناصرية",
            "slug_en": "sharjah/al-nasserya",
            "location": "الناصرية (الشارقة)",
            "type": 2
        },
        {
            "value": 211,
            "slug_ar": "الشارقة/العزرة",
            "slug_en": "sharjah/al-azra",
            "location": "العزرة (الشارقة)",
            "type": 2
        },
        {
            "value": 212,
            "slug_ar": "الشارقة/الرحمانية",
            "slug_en": "sharjah/al-rahmaniya",
            "location": "الرحمانية (الشارقة)",
            "type": 2
        },
        {
            "value": 215,
            "slug_ar": "الشارقة/اليرموك",
            "slug_en": "sharjah/al-yarmook",
            "location": "اليرموك (الشارقة)",
            "type": 2
        },
        {
            "value": 216,
            "slug_ar": "الشارقة/الفلج",
            "slug_en": "sharjah/al-falaj",
            "location": "الفلج (الشارقة)",
            "type": 2
        },
        {
            "value": 219,
            "slug_ar": "الشارقة/السور",
            "slug_en": "sharjah/al-soor",
            "location": "السور (الشارقة)",
            "type": 2
        },
        {
            "value": 221,
            "slug_ar": "الشارقة/الجادة",
            "slug_en": "sharjah/aljada",
            "location": "الجادة (الشارقة)",
            "type": 2
        },
        {
            "value": 222,
            "slug_ar": "الشارقة/الجبيل",
            "slug_en": "sharjah/al-jubail",
            "location": "الجبيل (الشارقة)",
            "type": 2
        },
        {
            "value": 224,
            "slug_ar": "الشارقة/الرمثاء",
            "slug_en": "sharjah/al-ramtha",
            "location": "الرمثاء (الشارقة)",
            "type": 2
        },
        {
            "value": 225,
            "slug_ar": "الشارقة/المريجة",
            "slug_en": "sharjah/al-mareija",
            "location": "المريجة (الشارقة)",
            "type": 2
        },
        {
            "value": 226,
            "slug_ar": "الشارقة/الصبخة",
            "slug_en": "sharjah/al-sabkha",
            "location": "الصبخة (الشارقة)",
            "type": 2
        },
        {
            "value": 228,
            "slug_ar": "الشارقة/النوف",
            "slug_en": "sharjah/al-noaf",
            "location": "النوف (الشارقة)",
            "type": 2
        },
        {
            "value": 229,
            "slug_ar": "الشارقة/الغافیة",
            "slug_en": "sharjah/al-ghafia",
            "location": "الغافیة (الشارقة)",
            "type": 2
        },
        {
            "value": 231,
            "slug_ar": "الشارقة/السيوح",
            "slug_en": "sharjah/al-suyoh",
            "location": "السيوح (الشارقة)",
            "type": 2
        },
        {
            "value": 233,
            "slug_ar": "الشارقة/النخيلات",
            "slug_en": "sharjah/al-nekhailat",
            "location": "النخيلات (الشارقة)",
            "type": 2
        },
        {
            "value": 237,
            "slug_ar": "الشارقة/السجع",
            "slug_en": "sharjah/al-saja",
            "location": "السجع (الشارقة)",
            "type": 2
        },
        {
            "value": 239,
            "slug_ar": "الشارقة/الياش",
            "slug_en": "sharjah/al-yash",
            "location": "الياش (الشارقة)",
            "type": 2
        },
        {
            "value": 240,
            "slug_ar": "عجمان/النعيمية",
            "slug_en": "ajman/al-nuaimiya",
            "location": "النعيمية (عجمان)",
            "type": 2
        },
        {
            "value": 241,
            "slug_ar": "عجمان/الراشدية",
            "slug_en": "ajman/al-rashidiya",
            "location": "الراشدية (عجمان)",
            "type": 2
        },
        {
            "value": 242,
            "slug_ar": "عجمان/عجمان-وسط-المدينة",
            "slug_en": "ajman/ajman-downtown",
            "location": "عجمان وسط المدينة (عجمان)",
            "type": 2
        },
        {
            "value": 243,
            "slug_ar": "عجمان/الروضة",
            "slug_en": "ajman/al-rawda",
            "location": "الروضة (عجمان)",
            "type": 2
        },
        {
            "value": 244,
            "slug_ar": "عجمان/الصوان",
            "slug_en": "ajman/al-sawan",
            "location": "الصوان (عجمان)",
            "type": 2
        },
        {
            "value": 245,
            "slug_ar": "عجمان/المويهات",
            "slug_en": "ajman/al-mowaihat",
            "location": "المويهات (عجمان)",
            "type": 2
        },
        {
            "value": 246,
            "slug_ar": "عجمان/الجرف",
            "slug_en": "ajman/al-jurf",
            "location": "الجرف (عجمان)",
            "type": 2
        },
        {
            "value": 247,
            "slug_ar": "عجمان/كورنيش-عجمان",
            "slug_en": "ajman/corniche-ajman",
            "location": "كورنيش عجمان (عجمان)",
            "type": 2
        },
        {
            "value": 248,
            "slug_ar": "عجمان/مدينة-الإمارات‬",
            "slug_en": "ajman/emirates-city",
            "location": "مدينة الإمارات‬ (عجمان)",
            "type": 2
        },
        {
            "value": 249,
            "slug_ar": "عجمان/جاردن-سيتي",
            "slug_en": "ajman/garden-city",
            "location": "جاردن سيتي (عجمان)",
            "type": 2
        },
        {
            "value": 250,
            "slug_ar": "عجمان/الحميدية",
            "slug_en": "ajman/al-hamidiyah",
            "location": "الحميدية (عجمان)",
            "type": 2
        },
        {
            "value": 251,
            "slug_ar": "عجمان/الرميلة",
            "slug_en": "ajman/al-rumaila",
            "location": "الرميلة (عجمان)",
            "type": 2
        },
        {
            "value": 252,
            "slug_ar": "عجمان/عجمان-الصناعية",
            "slug_en": "ajman/ajman-industrial",
            "location": "عجمان الصناعية (عجمان)",
            "type": 2
        },
        {
            "value": 253,
            "slug_ar": "عجمان/البستان",
            "slug_en": "ajman/al-bustan",
            "location": "البستان (عجمان)",
            "type": 2
        },
        {
            "value": 254,
            "slug_ar": "عجمان/مشيرف",
            "slug_en": "ajman/musherief",
            "location": "مشيرف (عجمان)",
            "type": 2
        },
        {
            "value": 256,
            "slug_ar": "عجمان/شارع-الشيخ-خليفة-بن-زايد",
            "slug_en": "ajman/sheikh-khalifa-bin-zayed-street",
            "location": "شارع الشيخ خليفة بن زايد (عجمان)",
            "type": 2
        },
        {
            "value": 257,
            "slug_ar": "عجمان/شارع-الملك-فيصل",
            "slug_en": "ajman/king-faisal-street",
            "location": "شارع الملك فيصل (عجمان)",
            "type": 2
        },
        {
            "value": 258,
            "slug_ar": "عجمان/الياسمين",
            "slug_en": "ajman/al-yasmeen",
            "location": "الياسمين (عجمان)",
            "type": 2
        },
        {
            "value": 259,
            "slug_ar": "عجمان/الزاهية",
            "slug_en": "ajman/al-zahya",
            "location": "الزاهية (عجمان)",
            "type": 2
        },
        {
            "value": 260,
            "slug_ar": "عجمان/العامرة",
            "slug_en": "ajman/al-amerah",
            "location": "العامرة (عجمان)",
            "type": 2
        },
        {
            "value": 262,
            "slug_ar": "عجمان/ليوارة-1",
            "slug_en": "ajman/liwara-1",
            "location": "ليوارة 1 (عجمان)",
            "type": 2
        },
        {
            "value": 263,
            "slug_ar": "عجمان/عجمان-أب-تاون",
            "slug_en": "ajman/ajman-uptown",
            "location": "عجمان أب تاون (عجمان)",
            "type": 2
        },
        {
            "value": 264,
            "slug_ar": "عجمان/منطقة-الكرامة",
            "slug_en": "ajman/al-karama-area",
            "location": "منطقة الكرامة (عجمان)",
            "type": 2
        },
        {
            "value": 265,
            "slug_ar": "عجمان/الحليو",
            "slug_en": "ajman/al-helio",
            "location": "الحليو (عجمان)",
            "type": 2
        },
        {
            "value": 297,
            "slug_ar": "رأس-الخيمة/دفن-النخیل",
            "slug_en": "ras-al-khaimah/dafan-al-nakheel",
            "location": "دفن النخیل (رأس الخيمة)",
            "type": 2
        },
        {
            "value": 310,
            "slug_ar": "أم-القيوين/المدار",
            "slug_en": "umm-al-quwain/al-madar",
            "location": "المدار (أم القيوين\n)",
            "type": 2
        },
        {
            "value": 325,
            "slug_ar": "عجمان/المنامة",
            "slug_en": "ajman/al-manama",
            "location": "المنامة (عجمان)",
            "type": 2
        },
        {
            "value": 330,
            "slug_ar": "الشارقة/ضاحية-حلوان",
            "slug_en": "sharjah/halwan-suburb",
            "location": "ضاحية حلوان (الشارقة)",
            "type": 2
        },
        {
            "value": 333,
            "slug_ar": "الشارقة/المرقاب",
            "slug_en": "sharjah/al-mirgab",
            "location": "المرقاب (الشارقة)",
            "type": 2
        },
        {
            "value": 336,
            "slug_ar": "الشارقة/ضاحية-الحيرة",
            "slug_en": "sharjah/al-heerah-suburb",
            "location": "ضاحية الحيرة (الشارقة)",
            "type": 2
        },
        {
            "value": 337,
            "slug_ar": "الشارقة/ضاحية-مغيدر",
            "slug_en": "sharjah/mughaidir-suburb",
            "location": "ضاحية مغيدر (الشارقة)",
            "type": 2
        },
        {
            "value": 338,
            "slug_ar": "الشارقة/مدينة-الإمارات-الصناعية",
            "slug_en": "sharjah/emirates-industrial-city",
            "location": "مدينة الإمارات الصناعية (الشارقة)",
            "type": 2
        },
        {
            "value": 341,
            "slug_ar": "الشارقة/دسمان",
            "slug_en": "sharjah/dasman",
            "location": "دسمان (الشارقة)",
            "type": 2
        },
        {
            "value": 343,
            "slug_ar": "الشارقة/الغبيبة",
            "slug_en": "sharjah/al-ghubaiba",
            "location": "الغبيبة (الشارقة)",
            "type": 2
        },
        {
            "value": 344,
            "slug_ar": "عجمان/مصفوت",
            "slug_en": "ajman/masfout",
            "location": "مصفوت (عجمان)",
            "type": 2
        },
        {
            "value": 345,
            "slug_ar": "أبوظبي/المارينا",
            "slug_en": "abu-dhabi/al-marina",
            "location": "المارينا (أبوظبي)",
            "type": 2
        },
        {
            "value": 348,
            "slug_ar": "عجمان/المدينة-العالمية",
            "slug_en": "ajman/global-city",
            "location": "المدينة العالمية (عجمان)",
            "type": 2
        },
        {
            "value": 349,
            "slug_ar": "عجمان/الرقايب",
            "slug_en": "ajman/al-raqaib",
            "location": "الرقايب (عجمان)",
            "type": 2
        },
        {
            "value": 350,
            "slug_ar": "عجمان/العلياء",
            "slug_en": "ajman/al-alia",
            "location": "العلياء (عجمان)",
            "type": 2
        },
        {
            "value": 14,
            "slug_en": "dubai/jumeirah-village-circle-jvc/seasons-community",
            "slug_ar": "دبي/قرية-جميرا-الدائرية/سيزونز-كوميونيتي",
            "location": "سيزونز كوميونيتي  قرية جميرا الدائرية (دبي)",
            "type": 3
        },
        {
            "value": 85,
            "slug_en": "dubai/jumeirah-village-circle-jvc/masaar-residence",
            "slug_ar": "دبي/قرية-جميرا-الدائرية/مساكن-مسار",
            "location": "مساكن مسار  قرية جميرا الدائرية (دبي)",
            "type": 3
        },
        {
            "value": 104,
            "slug_en": "dubai/dubai-marina/ocean-heights",
            "slug_ar": "دبي/دبي-مارينا/أوشن-هايتس",
            "location": "أوشن هايتس  دبي مارينا (دبي)",
            "type": 3
        },
        {
            "value": 133,
            "slug_en": "dubai/dubai-marina/23-marina",
            "slug_ar": "دبي/دبي-مارينا/مارينا-23",
            "location": "مارينا 23  دبي مارينا (دبي)",
            "type": 3
        },
        {
            "value": 168,
            "slug_en": "dubai/business-bay/sls-dubai-hotel-&-residences",
            "slug_ar": "دبي/الخليج-التجاري/فندق-إس-إل-إس-دبي",
            "location": "فندق إس إل إس دبي  الخليج التجاري (دبي)",
            "type": 3
        },
        {
            "value": 1205,
            "slug_en": "dubai/business-bay/silver-tower",
            "slug_ar": null,
            "location": "برج سلفر  الخليج التجاري (دبي)",
            "type": 3
        },
        {
            "value": 1206,
            "slug_en": "dubai/business-bay/oxford-tower",
            "slug_ar": null,
            "location": "برج أكسفورد  الخليج التجاري (دبي)",
            "type": 3
        },
        {
            "value": 244,
            "slug_en": "dubai/al-nahda-/al-nahda-2",
            "slug_ar": "دبي/النهدة/النهدة-2",
            "location": "النهدة 2  النهدة (دبي)",
            "type": 3
        },
        {
            "value": 246,
            "slug_en": "dubai/al-barsha/al-barsha-1",
            "slug_ar": "دبي/البرشاء/البرشاء-1",
            "location": "البرشاء 1  البرشاء (دبي)",
            "type": 3
        },
        {
            "value": 247,
            "slug_en": "dubai/al-barsha/al-barsha-south",
            "slug_ar": "دبي/البرشاء/البرشاء-جنوب",
            "location": "البرشاء جنوب  البرشاء (دبي)",
            "type": 3
        },
        {
            "value": 251,
            "slug_en": "dubai/bur-dubai/oud-metha",
            "slug_ar": "دبي/بر-دبي/عود-ميثاء",
            "location": "عود ميثاء  بر دبي (دبي)",
            "type": 3
        },
        {
            "value": 276,
            "slug_en": "dubai/deira/al-wuheida",
            "slug_ar": "دبي/ديرة/الوحيدة",
            "location": "الوحيدة  ديرة (دبي)",
            "type": 3
        },
        {
            "value": 483,
            "slug_en": "dubai/al-warqaa/al-warqaa-1",
            "slug_ar": "دبي/الورقاء/الورقاء-1",
            "location": "الورقاء 1  الورقاء (دبي)",
            "type": 3
        },
        {
            "value": 504,
            "slug_en": "dubai/international-city/warsan-village",
            "slug_ar": null,
            "location": "قرية ورسان  المدينة العالمية (دبي)",
            "type": 3
        },
        {
            "value": 578,
            "slug_en": "dubai/al-satwa/jumeirah-garden-city",
            "slug_ar": "دبي/السطوة/جميرا-جاردن-سيتي",
            "location": "جميرا جاردن سيتي  السطوة (دبي)",
            "type": 3
        },
        {
            "value": 632,
            "slug_en": "dubai/al-qusais/al-qusais-industrial-area",
            "slug_ar": "دبي/القصيص/القصيص-الصناعية",
            "location": "القصيص الصناعية  القصيص (دبي)",
            "type": 3
        },
        {
            "value": 730,
            "slug_en": "dubai/liwan/queue-point",
            "slug_ar": null,
            "location": "كيو بوينت  ليوان (دبي)",
            "type": 3
        },
        {
            "value": 896,
            "slug_en": "abu-dhabi/al-reem-island/tamouh",
            "slug_ar": "أبوظبي/جزيرة-الريم/طموح",
            "location": "طموح  جزيرة الريم (أبوظبي)",
            "type": 3
        },
        {
            "value": 1201,
            "slug_en": "abu-dhabi/al-reem-island/marina-heights",
            "slug_ar": "أبوظبي/جزيرة-الريم/مارينا-هايتس",
            "location": "مارينا هايتس  جزيرة الريم (أبوظبي)",
            "type": 3
        },
        {
            "value": 1203,
            "slug_en": "abu-dhabi/al-reem-island/bay-view-tower",
            "slug_ar": "أبوظبي/جزيرة-الريم/برج-باي-فيو",
            "location": "برج باي فيو  جزيرة الريم (أبوظبي)",
            "type": 3
        },
        {
            "value": 1202,
            "slug_en": "abu-dhabi/al-reem-island/leaf-tower",
            "slug_ar": "أبوظبي/جزيرة-الريم/برج-ورقة",
            "location": "برج ورقة  جزيرة الريم (أبوظبي)",
            "type": 3
        },
        {
            "value": 895,
            "slug_en": "abu-dhabi/al-reem-island/amaya-towers",
            "slug_ar": "أبوظبي/جزيرة-الريم/أبراج-أمایا",
            "location": "أبراج أمایا  جزيرة الريم (أبوظبي)",
            "type": 3
        },
        {
            "value": 889,
            "slug_en": "abu-dhabi/al-reem-island/marina-square",
            "slug_ar": "أبوظبي/جزيرة-الريم/مارينا-سكوير",
            "location": "مارينا سكوير  جزيرة الريم (أبوظبي)",
            "type": 3
        },
        {
            "value": 892,
            "slug_en": "abu-dhabi/al-reem-island/najmat-abu-dhabi",
            "slug_ar": "أبوظبي/جزيرة-الريم/نجمة-ابوظبي",
            "location": "نجمة ابوظبي  جزيرة الريم (أبوظبي)",
            "type": 3
        },
        {
            "value": 927,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-4",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-4",
            "location": "المنطقة 4  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 917,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-30",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-30",
            "location": "المنطقة 30  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 915,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-13",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-13",
            "location": "المنطقة 13  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 911,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-22",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-22",
            "location": "المنطقة 22  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 920,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-15",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-15",
            "location": "المنطقة 15  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 921,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-16",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-16",
            "location": "المنطقة 16  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 900,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-24",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-24",
            "location": "المنطقة 24  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 918,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-34",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-34",
            "location": "المنطقة 34  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 909,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-17",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-17",
            "location": "المنطقة 17  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 904,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-33",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-33",
            "location": "المنطقة 33  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 908,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-27",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-27",
            "location": "المنطقة 27  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 914,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-5",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-5",
            "location": "المنطقة 5  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 923,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-6",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-6",
            "location": "المنطقة 6  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 902,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-20",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-20",
            "location": "المنطقة 20  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 907,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-36",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-36",
            "location": "المنطقة 36  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 924,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-3",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-3",
            "location": "المنطقة 3  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 901,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-25",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-25",
            "location": "المنطقة 25  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 919,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/zone-8",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/المنطقة-8",
            "location": "المنطقة 8  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 928,
            "slug_en": "abu-dhabi/mohammed-bin-zayed-city/mazyad-mall",
            "slug_ar": "أبوظبي/مدينة-محمد-بن-زايد/مزيد-مول",
            "location": "مزيد مول  مدينة محمد بن زايد (أبوظبي)",
            "type": 3
        },
        {
            "value": 953,
            "slug_en": "abu-dhabi/khalifa-city-/al-forsan-village",
            "slug_ar": "أبوظبي/مدينة-خليفة-أ/قرية-الفرسان‬",
            "location": "قرية الفرسان‬  مدينة خليفة أ (أبوظبي)",
            "type": 3
        },
        {
            "value": 957,
            "slug_en": "abu-dhabi/yas-island/water's-edge",
            "slug_ar": "أبوظبي/جزيرة-ياس/وترز-أج",
            "location": "وترز أج  جزيرة ياس (أبوظبي)",
            "type": 3
        },
        {
            "value": 960,
            "slug_en": "abu-dhabi/yas-island/west-yas",
            "slug_ar": "أبوظبي/جزيرة-ياس/وست-ياس",
            "location": "وست ياس  جزيرة ياس (أبوظبي)",
            "type": 3
        },
        {
            "value": 958,
            "slug_en": "abu-dhabi/yas-island/mayan",
            "slug_ar": "أبوظبي/جزيرة-ياس/مايان",
            "location": "مايان  جزيرة ياس (أبوظبي)",
            "type": 3
        },
        {
            "value": 962,
            "slug_en": "abu-dhabi/al-khalidiyah/khalidiyah-street",
            "slug_ar": "أبوظبي/الخالدية/شارع-الخالدية",
            "location": "شارع الخالدية  الخالدية (أبوظبي)",
            "type": 3
        },
        {
            "value": 976,
            "slug_en": "abu-dhabi/al-reef/al-reef-downtown",
            "slug_ar": "أبوظبي/جزيرة-السعديات/الریف-داون-تاون",
            "location": "الریف داون تاون  جزيرة السعديات (أبوظبي)",
            "type": 3
        },
        {
            "value": 975,
            "slug_en": "abu-dhabi/al-reef/al-reef-villas",
            "slug_ar": "أبوظبي/جزيرة-السعديات/فلل-الريف",
            "location": "فلل الريف  جزيرة السعديات (أبوظبي)",
            "type": 3
        },
        {
            "value": 980,
            "slug_en": "abu-dhabi/saadiyat-island/hidd-al-saadiyat",
            "slug_ar": "أبوظبي/الريف/حِد-السعديات",
            "location": "حِد السعديات  الريف (أبوظبي)",
            "type": 3
        },
        {
            "value": 994,
            "slug_en": "abu-dhabi/al-muroor/muroor-road",
            "slug_ar": "أبوظبي/المرور/شارع-المرور",
            "location": "شارع المرور  المرور (أبوظبي)",
            "type": 3
        },
        {
            "value": 1001,
            "slug_en": "abu-dhabi/mussafah/shabiya",
            "slug_ar": "أبوظبي/مصفح/شعبية",
            "location": "شعبية  مصفح (أبوظبي)",
            "type": 3
        },
        {
            "value": 1013,
            "slug_en": "abu-dhabi/al-mushrif/delma-street",
            "slug_ar": "أبوظبي/المشرف/شارع-دلما",
            "location": "شارع دلما  المشرف (أبوظبي)",
            "type": 3
        },
        {
            "value": 1055,
            "slug_en": "abu-dhabi/eastern-road/khalifa-park",
            "slug_ar": "أبوظبي/الطريق-الشرقي/منتزه-خليفة",
            "location": "منتزه خليفة  الطريق الشرقي (أبوظبي)",
            "type": 3
        },
        {
            "value": 1096,
            "slug_en": "sharjah/muwaileh/al-zahia",
            "slug_ar": "الشارقة/مويلح/الزاهية",
            "location": "الزاهية  مويلح (الشارقة)",
            "type": 3
        },
        {
            "value": 1105,
            "slug_en": "sharjah/al-taawun/al-taawun-street",
            "slug_ar": "الشارقة/التعاون/شارع-التعاون",
            "location": "شارع التعاون  التعاون (الشارقة)",
            "type": 3
        },
        {
            "value": 1109,
            "slug_en": "sharjah/al-majaz/al-majaz-2",
            "slug_ar": "الشارقة/المجاز/المجاز-2",
            "location": "المجاز 2  المجاز (الشارقة)",
            "type": 3
        },
        {
            "value": 1108,
            "slug_en": "sharjah/al-majaz/al-majaz-3",
            "slug_ar": "الشارقة/المجاز/المجاز-3",
            "location": "المجاز 3  المجاز (الشارقة)",
            "type": 3
        },
        {
            "value": 1110,
            "slug_en": "sharjah/al-majaz/al-majaz-1",
            "slug_ar": "الشارقة/المجاز/المجاز-1",
            "location": "المجاز 1  المجاز (الشارقة)",
            "type": 3
        },
        {
            "value": 1119,
            "slug_en": "sharjah/al-qasimia/al-nud",
            "slug_ar": "الشارقة/القاسمية/الند",
            "location": "الند  القاسمية (الشارقة)",
            "type": 3
        },
        {
            "value": 1127,
            "slug_en": "sharjah/industrial-area/industrial-area-1",
            "slug_ar": "الشارقة/المنطقة-الصناعية/المنطقة-الصناعية-1",
            "location": "المنطقة الصناعية 1  المنطقة الصناعية (الشارقة)",
            "type": 3
        },
        {
            "value": 1129,
            "slug_en": "sharjah/industrial-area/industrial-area-11",
            "slug_ar": "الشارقة/المنطقة-الصناعية/المنطقة-الصناعية-11",
            "location": "المنطقة الصناعية 11  المنطقة الصناعية (الشارقة)",
            "type": 3
        },
        {
            "value": 1128,
            "slug_en": "sharjah/industrial-area/industrial-area-4",
            "slug_ar": "الشارقة/المنطقة-الصناعية/المنطقة-الصناعية-4",
            "location": "المنطقة الصناعية 4  المنطقة الصناعية (الشارقة)",
            "type": 3
        },
        {
            "value": 1130,
            "slug_en": "sharjah/industrial-area/industrial-area-13",
            "slug_ar": "الشارقة/المنطقة-الصناعية/المنطقة-الصناعية-13",
            "location": "المنطقة الصناعية 13  المنطقة الصناعية (الشارقة)",
            "type": 3
        },
        {
            "value": 1133,
            "slug_en": "sharjah/rolla-area/rolla-square",
            "slug_ar": "الشارقة/منطقة-الرولة/ميدان-الرولة",
            "location": "ميدان الرولة  منطقة الرولة (الشارقة)",
            "type": 3
        },
        {
            "value": 1200,
            "slug_en": "ajman/al-nuaimiya/city-tower",
            "slug_ar": "عجمان/النعيمية/برج-المدينة",
            "location": "برج المدينة  النعيمية (عجمان)",
            "type": 3
        },
        {
            "value": 1134,
            "slug_en": "ajman/al-nuaimiya/al-nuaimiya-3",
            "slug_ar": "عجمان/النعيمية/النعيمية-3",
            "location": "النعيمية 3  النعيمية (عجمان)",
            "type": 3
        },
        {
            "value": 1137,
            "slug_en": "ajman/al-nuaimiya/al-nuaimiya-1",
            "slug_ar": "عجمان/النعيمية/النعيمية-1",
            "location": "النعيمية 1  النعيمية (عجمان)",
            "type": 3
        },
        {
            "value": 1136,
            "slug_en": "ajman/al-nuaimiya/al-nuaimiya-towers",
            "slug_ar": "عجمان/النعيمية/أبراج-النعيمية",
            "location": "أبراج النعيمية  النعيمية (عجمان)",
            "type": 3
        },
        {
            "value": 1135,
            "slug_en": "ajman/al-nuaimiya/al-nuaimiya-2",
            "slug_ar": "عجمان/النعيمية/النعيمية-2",
            "location": "النعيمية 2  النعيمية (عجمان)",
            "type": 3
        },
        {
            "value": 1142,
            "slug_en": "ajman/al-rashidiya/falcon-towers",
            "slug_ar": "عجمان/الراشدية/فالكون-تاورز",
            "location": "فالكون تاورز  الراشدية (عجمان)",
            "type": 3
        },
        {
            "value": 1143,
            "slug_en": "ajman/al-rashidiya/oasis-tower",
            "slug_ar": "عجمان/الراشدية/أبراج-الواحة",
            "location": "أبراج الواحة  الراشدية (عجمان)",
            "type": 3
        },
        {
            "value": 1144,
            "slug_en": "ajman/al-rashidiya/zara-residence",
            "slug_ar": "عجمان/الراشدية/زارا-ريزيدنس",
            "location": "زارا ريزيدنس  الراشدية (عجمان)",
            "type": 3
        },
        {
            "value": 1140,
            "slug_en": "ajman/al-rashidiya/al-rashidiya-3",
            "slug_ar": "عجمان/الراشدية/الراشدية-3",
            "location": "الراشدية 3  الراشدية (عجمان)",
            "type": 3
        },
        {
            "value": 1138,
            "slug_en": "ajman/al-rashidiya/al-rashidiya-2",
            "slug_ar": "عجمان/الراشدية/الراشدية-2",
            "location": "الراشدية 2  الراشدية (عجمان)",
            "type": 3
        },
        {
            "value": 1141,
            "slug_en": "ajman/al-rashidiya/al-rashidiya-1",
            "slug_ar": "عجمان/الراشدية/الراشدية-1",
            "location": "الراشدية 1  الراشدية (عجمان)",
            "type": 3
        },
        {
            "value": 1147,
            "slug_en": "ajman/ajman-downtown/ajman-pearl-towers",
            "slug_ar": "عجمان/عجمان-وسط-المدينة/أبراج-لؤلؤة-عجمان",
            "location": "أبراج لؤلؤة عجمان  عجمان وسط المدينة (عجمان)",
            "type": 3
        },
        {
            "value": 1145,
            "slug_en": "ajman/ajman-downtown/horizon-tower",
            "slug_ar": "عجمان/عجمان-وسط-المدينة/أبراج-الهورايزون",
            "location": "أبراج الهورايزون  عجمان وسط المدينة (عجمان)",
            "type": 3
        },
        {
            "value": 1146,
            "slug_en": "ajman/ajman-downtown/al-khor-towers",
            "slug_ar": "عجمان/عجمان-وسط-المدينة/أبراج-الخور",
            "location": "أبراج الخور  عجمان وسط المدينة (عجمان)",
            "type": 3
        },
        {
            "value": 1149,
            "slug_en": "ajman/al-rawda/al-rawda-2",
            "slug_ar": "عجمان/الروضة/الروضة-2",
            "location": "الروضة 2  الروضة (عجمان)",
            "type": 3
        },
        {
            "value": 1148,
            "slug_en": "ajman/al-rawda/al-rawda-3",
            "slug_ar": "عجمان/الروضة/الروضة-3",
            "location": "الروضة 3  الروضة (عجمان)",
            "type": 3
        },
        {
            "value": 1150,
            "slug_en": "ajman/al-rawda/al-rawda-1",
            "slug_ar": "عجمان/الروضة/الروضة-1",
            "location": "الروضة 1  الروضة (عجمان)",
            "type": 3
        },
        {
            "value": 1151,
            "slug_en": "ajman/al-sawan/ajman-one-tower",
            "slug_ar": "عجمان/الصوان/أبراج-عجمان-ون",
            "location": "أبراج عجمان ون  الصوان (عجمان)",
            "type": 3
        },
        {
            "value": 1154,
            "slug_en": "ajman/al-mowaihat/al-mowaihat-1",
            "slug_ar": "عجمان/المويهات/المويهات-1",
            "location": "المويهات 1  المويهات (عجمان)",
            "type": 3
        },
        {
            "value": 1152,
            "slug_en": "ajman/al-mowaihat/al-mowaihat-3",
            "slug_ar": "عجمان/المويهات/المويهات-3",
            "location": "المويهات 3  المويهات (عجمان)",
            "type": 3
        },
        {
            "value": 1158,
            "slug_en": "ajman/al-jurf/al-jurf-1",
            "slug_ar": "عجمان/الجرف/الجرف-1",
            "location": "الجرف 1  الجرف (عجمان)",
            "type": 3
        },
        {
            "value": 1156,
            "slug_en": "ajman/al-jurf/al-jurf-3",
            "slug_ar": "عجمان/الجرف/الجرف-3",
            "location": "الجرف 3  الجرف (عجمان)",
            "type": 3
        },
        {
            "value": 1157,
            "slug_en": "ajman/al-jurf/al-jurf-industrial-area",
            "slug_ar": "عجمان/الجرف/المنطقة-الصناعية-الجرف",
            "location": "المنطقة الصناعية الجرف  الجرف (عجمان)",
            "type": 3
        },
        {
            "value": 1155,
            "slug_en": "ajman/al-jurf/al-jurf-2",
            "slug_ar": "عجمان/الجرف/الجرف-2",
            "location": "الجرف 2  الجرف (عجمان)",
            "type": 3
        },
        {
            "value": 1160,
            "slug_en": "ajman/corniche-ajman/corniche-tower",
            "slug_ar": "عجمان/كورنيش-عجمان/برج-الكورنيش",
            "location": "برج الكورنيش  كورنيش عجمان (عجمان)",
            "type": 3
        },
        {
            "value": 1159,
            "slug_en": "ajman/corniche-ajman/ajman-corniche-residence",
            "slug_ar": "عجمان/كورنيش-عجمان/مساكن-كورنيش-عجمان",
            "location": "مساكن كورنيش عجمان  كورنيش عجمان (عجمان)",
            "type": 3
        },
        {
            "value": 1159,
            "slug_en": "ajman/corniche-ajman/ajman-corniche-residence",
            "slug_ar": null,
            "location": "مساكن كورنيش عجمان  كورنيش عجمان (عجمان)",
            "type": 3
        },
        {
            "value": 1208,
            "slug_en": "ajman/emirates-city/emirates-towers",
            "slug_ar": "عجمان/مدينة-الإمارات‬/ابراج-الامارات",
            "location": "ابراج الامارات  مدينة الإمارات‬ (عجمان)",
            "type": 3
        },
        {
            "value": 1168,
            "slug_en": "ajman/al-hamidiyah/al-hamidiya-1",
            "slug_ar": "عجمان/الحميدية/الحميدية-1",
            "location": "الحميدية 1  الحميدية (عجمان)",
            "type": 3
        },
        {
            "value": 1170,
            "slug_en": "ajman/al-bustan/orient-towers",
            "slug_ar": "عجمان/البستان/أبراج-أورينت",
            "location": "أبراج أورينت  البستان (عجمان)",
            "type": 3
        },
        {
            "value": 1196,
            "slug_en": "ras-al-khaimah/dafan-al-nakheel/julphar-towers",
            "slug_ar": "رأس-الخيمة/دفن-النخیل/جلفار-تاورز",
            "location": "جلفار تاورز  دفن النخیل (رأس الخيمة)",
            "type": 3
        },
        {
            "value": 1210,
            "slug_en": "ajman/al-raqaib/al-raqaib-2",
            "slug_ar": "عجمان/الرقايب/الرقايب-2",
            "location": "الرقايب 2  الرقايب (عجمان)",
            "type": 3
        }
    ]
}